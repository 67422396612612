/** @format */

import React from "react";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

import BackgroudImage from "../assets/images/bg/2.jpg";

import Services from "../component/Services";
import Pricing from "../component/Pricing";
import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";
import Switcher from "../component/Switcher";
import Navbar from "../component/navbar";
import PriceCalculator from "../component/PriceCalculator";

/**
 * Index Two
 */
export default function Calculator() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar />
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="py-36 lg:py-72 lg:pb-0 md:pb-8 w-full table relative bg-[url('../../assets/images/bg/2.jpg')] bg-center bg-cover"
        id="home"
      >
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container relative">
          <div className="grid lg:grid-cols-12 mt-10 md:grid-cols-2 gap-2">
            <div className="lg:col-span-7">
              <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                Calculate the Cost <br /> of Your Web Project in Minutes
              </h4>

              <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
                Explore your project’s potential budget with a few clicks. Start
                with a quick estimate and get insights into how we can bring
                your ideas to life.
              </p>

              <div className="relative mt-10">
                <button
                  onClick={() => scrollToSection("calculator")}
                  className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full me-1"
                >
                  Get Your Website Stimate Now
                </button>
              </div>
            </div>

            <div className="lg:col-span-5 mt-8 md:mt-0">
              <div className="bg-white dark:bg-slate-900 rounded-xl shadow md:p-10 p-6 lg:ms-10 z-10 relative">
                <h4 className="mb-5 font-medium text-2xl dark:text-white">
                  We are offering FREE <br /> Web Development
                </h4>
                <p className="text-slate-400 dark:text-slate-300">
                  We’re thrilled to offer FREE web development services to our
                  first 50 clients! This limited-time offer is our way of giving
                  back while gathering valuable feedback to refine our services.
                  Whether you’re looking to launch a new website or revamp an
                  existing one, our team is here to make it happen at no cost to
                  you. Curious about what’s included? Visit our pricing section
                  to explore the full range of features available in this
                  exclusive offer{" "}
                </p>
                <button
                  className="btn mt-5 bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-1/2"
                  onClick={() => scrollToSection("pricing")}
                >
                  pricing
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape overflow-hidden text-gray-50 dark:text-slate-800">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* About section */}
      <PriceCalculator />
      {/* Service section */}
      <Services />
      {/* Portfolio section */}
      <Portfolio />
      {/* Review section
      <Review /> */}
      {/* Pricing section */}
      <Pricing />
      {/* Blog section */}
      <Blog />
      {/* Contact section */}
      <Contact />
      {/* Footer section */}
      <Footer />
      {/* Switcher section */}
      <Switcher />
    </>
  );
}
