import React from 'react';

const serializers = {
  types: {
    block(props) {
      const content = props.children[0];
      const hasDoubleQuotes = typeof content === 'string' && content.includes('"');

      if (hasDoubleQuotes) {
        return (
          <blockquote className="text-slate-400 italic border-x-4 border-orange-600 rounded-ss-xl rounded-ee-xl mt-3 p-3">
            {props.children}
          </blockquote>
        );
      }

      switch (props.node.style) {
        case 'h1':
          return <h1 className="text-2xl font-bold my-4">{props.children}</h1>;
        case 'h2':
          return <h2 className="text-xl font-bold my-4">{props.children}</h2>;
        case 'h3':
          return <h3 className="text-lg font-bold my-4">{props.children}</h3>;
        case 'h4':
          return <h4 className="text-md font-bold my-4">{props.children}</h4>;
        case 'blockquote':
          return <blockquote className="pl-4 border-l-4 border-gray-500 italic my-4">{props.children}</blockquote>;
        default:
          return <p className="my-4">{props.children}</p>;
      }
    },
    list(props){
      const { type } = props; // "bullet" or "number"
      const isBullet = type === 'bullet';
      if (isBullet) {
        return <ul className="list-disc list-inside my-4">{props.children}</ul>;
      }
      return <ol className="list-decimal list-inside my-4">{props.children}</ol>;
    },
    listItem(props) {
      return <li className="my-2">{props.children}</li>;
    },
    link(props){
      return (
        <a
          href={props.mark.href}
          className="text-blue-500 underline hover:text-blue-700"
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      );
    },
    marks: {
      italic(props) {
        return <em className="italic">{props.children}</em>;
      },
      strong(props) {
        return <strong className="font-bold">{props.children}</strong>;
      },
      
    },
  },

};


export default serializers;
