/** @format */

import React, { useState } from "react";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import "../assets/scss/tailwind.scss";
import PricingTable from "./PricingTable";

export default function PriceCalculator() {
  const [pages, setPages] = useState(1);
  const [style, setStyle] = useState(["No design needed"]);
  const [copywriting, setCopywriting] = useState(0);
  const [seo, setSeo] = useState(0);
  const [responsive, setResponsive] = useState(["No"]);
  const [database, setDatabase] = useState(0);
  const [ecommerce, setEcommerce] = useState(0);
  const [cms, setCms] = useState(0);
  const [showPricing, setShowPricing] = useState(false);
  const [details, setDetails] = useState({});

  // Options for sliders with predefined values
  const styleOptions = [
    "No design needed",
    "Basic design",
    "Moderate design",
    "High-end design",
  ];

  const yesNoOptions = ["No", "Yes"];

  const integrationOptions = ["None", "Basic", "Advanced"];

  const seoKeywords = ["0", "30", "80", "150"];

  const handleCheckboxChange = (setStyle, option) => {
    setStyle((prevStyle) => {
      if (prevStyle.includes(option)) {
        // If the option is already selected, remove it
        return prevStyle.filter((item) => item !== option);
      } else {
        // If the option is not selected, clear all other selections and add the new one
        return [option];
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or price calculation here
    const pricingDetails = {
      pages,
      style,
      copywriting,
      seo: seoKeywords[seo],
      responsive,
      database: integrationOptions[database],
      ecommerce: integrationOptions[ecommerce],
      cms: integrationOptions[cms],
    };
    setDetails(pricingDetails);
    setShowPricing(true);
  };

  return (
    <section
      className="overflow-x-hidden relative flex flex-col justify-center md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
      id="calculator"
    >
      <div className="container mx-auto">
        <form
          id="cms-quote"
          method="post"
          action=""
          className="w-full price-calculator"
          onSubmit={handleSubmit}
        >
          <h2 className="uppercase text-2xl text-center font-bold mb-4">
            How Much Does a Website Cost?
          </h2>
          <p className="mb-4 text-center text-slate-400 dark:text-slate-300">
            Estimating the cost of developing, designing, and optimizing a
            website can feel tricky since every website is unique! That's why we
            provide our free website price calculator to give you a clear idea
            of what your project might cost.
          </p>
          <h4 className="text-xl text-center font-semibold mb-4">
            Website Price Estimate Calculator
          </h4>
          <p className="mb-8 text-center text-slate-400 dark:text-slate-300">
            Adjust the sliders below to match your needs, then click "Calculate"
            for a <span className=" highlight">free, instant quote.</span>
          </p>

          <div
            id="desktop-calc"
            className="flex w-full p-6 rounded-md shadow bg-white dark:bg-slate-900 flex-col items-center"
          >
            <fieldset className="w-4/5 ">
              {/* Number of Pages */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-center">
                <label className="block font-medium mb-2 md:mb-0 w-full md:w-1/3">
                  Number of pages
                </label>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={pages}
                  onChange={(e) => setPages(e.target.value)}
                  className="slider w-full ml-2"
                />
                <span className="active-value mt-2 md:mt-0 w-auto md:w-1/4 bg-orange-100 text-orange-600 font-bold rounded-lg p-2  text-left ml-5  md:block self-end">
                  {pages} page{pages > 1 ? "s" : ""}
                </span>
              </div>

              {/* Style of Design */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-center">
                <label className="block font-medium mb-2 md:mb-0 md:w-1/3">
                  Style of design
                </label>
                <div className="w-2/3 ">
                  {styleOptions.map((option, index) => (
                    <div key={index} className="flex  items-center mb-2">
                      <input
                        type="checkbox"
                        value={option}
                        checked={style.includes(option)}
                        onChange={() => handleCheckboxChange(setStyle, option)}
                        className="mr-2  single-checkbox"
                      />
                      <label className="text-slate-400 dark:text-slate-300">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Copywriting # of Pages */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-center">
                <label className="block font-medium mb-2 w-full md:mb-0  md:w-1/3">
                  Copywriting # of pages
                </label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={copywriting}
                  onChange={(e) => setCopywriting(e.target.value)}
                  className="slider w-full ml-2"
                />
                <span className="active-value mt-2 md:mt-0 w-auto md:w-1/4 bg-orange-100 text-orange-600 font-bold rounded-lg p-2  text-left  md:block self-end">
                  {copywriting} page{copywriting !== "1" ? "s" : ""}
                </span>
              </div>

              {/* SEO Placement */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-center">
                <label className="block font-medium mb-2 w-full md:mb-0  md:w-1/3">
                  SEO Placement
                </label>
                <input
                  type="range"
                  min="0"
                  max={seoKeywords.length - 1}
                  value={seo}
                  onChange={(e) => setSeo(e.target.value)}
                  className="slider w-full ml-2"
                />
                <span className="active-value mt-2 md:mt-0 w-auto md:w-1/4 bg-orange-100 text-orange-600 font-bold rounded-lg p-2  text-left ml-5  md:block self-end">
                  {seoKeywords[seo]} words
                </span>
              </div>

              {/* Responsive Design */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-cente">
                <label className="block font-medium mb-2 md:mb-0 md:w-1/3">
                  Responsive Design
                </label>
                <div className="w-2/3">
                  {yesNoOptions.map((option, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        value={option}
                        checked={responsive.includes(option)}
                        onChange={() =>
                          handleCheckboxChange(setResponsive, option)
                        }
                        className="mr-2 single-checkbox"
                      />
                      <label className="text-slate-400 dark:text-slate-300">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Database Integration */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-center">
                <label className="block font-medium mb-2 w-full md:mb-0  md:w-1/3">
                  Database Integration
                </label>
                <input
                  type="range"
                  min="0"
                  max={integrationOptions.length - 1}
                  value={database}
                  onChange={(e) => setDatabase(e.target.value)}
                  className="slider w-full ml-2"
                />
                <span className="active-value mt-2 md:mt-0 w-auto md:w-1/4 bg-orange-100 text-orange-600 font-bold rounded-lg p-2  text-left ml-5  md:block self-end">
                  {integrationOptions[database]}
                </span>
              </div>

              {/* e-Commerce Functionality */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-center">
                <label className="block font-medium mb-2 w-full md:mb-0  md:w-1/3">
                  e-Commerce Functionality
                </label>
                <input
                  type="range"
                  min="0"
                  max={integrationOptions.length - 1}
                  value={ecommerce}
                  onChange={(e) => setEcommerce(e.target.value)}
                  className="slider w-full ml-2"
                />
                <span className="active-value mt-2 md:mt-0 w-auto md:w-1/4 bg-orange-100 text-orange-600 font-bold rounded-lg p-2  text-left ml-5  md:block self-end">
                  {integrationOptions[ecommerce]}
                </span>
              </div>

              {/* CMS */}
              <div className="row mb-6 flex flex-col md:flex-row items-start md:items-center">
                <label className="block font-medium mb-2 w-full md:mb-0  md:w-1/3">
                  CMS
                </label>
                <input
                  type="range"
                  min="0"
                  max={integrationOptions.length - 1}
                  value={cms}
                  onChange={(e) => setCms(e.target.value)}
                  className="slider w-full ml-2"
                />
                <span className="active-value mt-2 md:mt-0 w-auto md:w-1/4 bg-orange-100 text-orange-600 font-bold rounded-lg p-2  text-left ml-5  md:block self-end">
                  {integrationOptions[cms]}
                </span>
              </div>
            </fieldset>

            <div className="button-row center mt-8 ">
              <input
                id="calculate"
                type="submit"
                className="btn btn-primary submit bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-full"
                value="Calculate"
              />
            </div>
          </div>
        </form>
        {showPricing && <PricingTable pricingData={details} />}
      </div>
    </section>
  );
}
