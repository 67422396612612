import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../component/navbar";
import Blog from "../component/Blog";
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";
import GetInTouch from "../component/GetInTouch";
import client from "../client.js";
import BlockContent from '@sanity/block-content-to-react';
import serializers from "../serializers.js";

export default function BlogDetail(){
    
    const { slug } = useParams();
    const [post, setPost] = useState(null);
  
    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
          console.log("Fetching data for slug:", slug);
          try {
            const data = await client.fetch(
              `*[slug.current == $slug]{
                title,
                slug,
                mainImage{
                  asset->{
                    _id,
                    url
                  }
                },
                body,
                publishedAt,
                categories[]->{
                title
                },
                "name": author->name,
                "authorImage": author->image.asset->{
                  _id,
                  url
                },
                "instagram": author->instagram,
                "x": author->x,
                "facebook": author->facebook,
                "bio": author->bio,
              }`,
              { slug }
            );
    
            if (isMounted) {
              console.log(data);
              setPost(data[0]);
            }
          } catch (error) {
            console.log(error);
          }
        };
    
        fetchData();
    
        return () => {
          isMounted = false;
        };
      }, [slug]);
    
      if (!post) {
        return <div>Loading...</div>;
      }

      const formattedDate = new Date(post.publishedAt).toLocaleDateString();

    return(
        <>
        <Navbar/>
        <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/auth.jpg')] bg-top bg-no-repeat">
            <div className="absolute inset-0 bg-slate-950/80"></div>

            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h1 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">{post.title}</h1>

                    <ul className="list-none mt-6">
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Category :</span> <span className="block">{post.categories.map(category => category.title).join(', ')}</span></li>
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block">{formattedDate}</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                    <div className="lg:col-span-8 md:col-span-6">
                        <div className="p-6 rounded-md shadow dark:shadow-gray-800">

                            {post.mainImage && post.mainImage.asset && (
        <img
          src={post.mainImage.asset.url}
          alt={post.title}
          className="rounded-md"
        />
      )}

                            <div className="mt-6">
                            <BlockContent dataset={'production'} projectId={'fgl7rm63'} blocks={post.body} serializers={serializers} />
                            </div>
                        </div>

                        <div className="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
                            <h5 className="text-lg font-semibold">Leave A Comment:</h5>

                            <form className="mt-8">
                                <div className="grid lg:grid-cols-12 gap-6">
                                    <div className="lg:col-span-6">
                                        <input name="name" id="name" type="text" className="form-input" placeholder="Name :"/>
                                    </div>
    
                                    <div className="lg:col-span-6">
                                        <input name="email" id="email" type="email" className="form-input" placeholder="Email :"/>
                                    </div>

                                    <div className="lg:col-span-12">
                                        <textarea name="comments" id="comments" className="form-input textarea h-28" placeholder="Message :"></textarea>
                                    </div>

                                    <div className="lg:col-span-12">
                                        <button type="submit" id="submit" name="send" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="sticky top-20">
                            <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Author</h5>
                            <div className="text-center mt-8">
                            {post.authorImage && (
                    <img
                      src={post.authorImage.url}
                      className="h-24 w-24 mx-auto rounded-full shadow mb-4"
                      alt={post.name}
                    />
                  )}
                                <Link to="" className="text-lg font-medium hover:text-orange-600 transition-all duration-500 ease-in-out h5">{post.name}</Link>
                                <p className="text-slate-400"><BlockContent
                      blocks={post.bio}
                      serializers={serializers}
                      projectId="fgl7rm63"
                      dataset="production"
                    /></p>
                                
                            </div>

                            <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Social sites</h5>
                            <ul className="list-none text-center mt-8 space-x-1">
                                <li className="inline"><Link to={post.facebook} className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-orange-600 hover:text-white hover:bg-orange-600"><i className="uil uil-facebook-f align-middle"></i></Link></li>
                                <li className="inline"><Link to={post.instagram} className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-orange-600 hover:text-white hover:bg-orange-600"><i className="uil uil-instagram align-middle"></i></Link></li>
                                <li className="inline"><Link to={post.x} className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-orange-600 hover:text-white hover:bg-orange-600"><i className="uil uil-twitter align-middle"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

           <Blog/>

            <GetInTouch/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}