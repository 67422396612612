/** @format */

import React, { useEffect, useState } from "react";
import "../assets/scss/tailwind.scss";
import Loader from "./Loader";

export default function PricingTable({ pricingData }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Reset loading state to true when pricingData changes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer);
  }, [pricingData]);

  const calculateCost = (feature, value, pages) => {
    switch (feature) {
      case "pages":
        if (value <= 1) {
          return { low: 300, high: 750 };
        } else {
          const additionalPages = value - 1;
          return {
            low: Math.round(300 + additionalPages * 100),
            high: Math.round(300 + additionalPages * 200),
          };
        }
      case "style":
        const designStyle = Array.isArray(value) ? value[0] : value;
        let baseCost, highBaseCost, perPageLow, perPageHigh;
        switch (designStyle) {
          case "No design needed":
            baseCost = 0;
            highBaseCost = 0;
            perPageLow = 0;
            perPageHigh = 0;
            break;
          case "Basic design":
            baseCost = 100;
            highBaseCost = 300;
            perPageLow = 20; // Low per-page cost for basic design
            perPageHigh = 50;
            break;
          case "Moderate design":
            baseCost = 200;
            highBaseCost = 450;
            perPageLow = 40; // Moderate per-page cost
            perPageHigh = 80;
            break;
          case "High-end design":
            baseCost = 400;
            highBaseCost = 650;
            perPageLow = 80; // Higher per-page cost for high-end design
            perPageHigh = 150;
            break;
          default:
            baseCost = 0;
            highBaseCost = 0;
            perPageLow = 0;
            perPageHigh = 0;
        }

        // Calculate total cost based on number of pages
        if (pages <= 1) {
          return { low: baseCost, high: highBaseCost };
        } else {
          const additionalPages = pages - 1;
          return {
            low: Math.round(baseCost + additionalPages * perPageLow),
            high: Math.round(baseCost + additionalPages * perPageHigh),
          };
        }
      case "copywriting":
        if (value === 0) {
          return { low: 0, high: 0 };
        }
        return { low: Math.round(pages * 50), high: Math.round(pages * 100) };

      case "seo":
        switch (value) {
          case "30":
            return { low: 2000, high: 4000 };
          case "80":
            return { low: 4000, high: 6000 };
          case "150":
            return { low: 6000, high: 10000 };
          default:
            return { low: 0, high: 0 };
        }
      case "responsive":
        const isResponsive = Array.isArray(value) ? value[0] : value;
        switch (isResponsive) {
          case "Yes":
            return { low: 1000, high: 3000 };
          case "No":
            return { low: 0, high: 0 };
          default:
            return { low: 0, high: 0 };
        }
      case "database":
        const databaseRequired = Array.isArray(value) ? value[0] : value;
        switch (databaseRequired) {
          case "None":
            return { low: 0, high: 0 };
          case "Basic":
            return { low: 2000, high: 4000 };
          case "Advanced":
            return { low: 4000, high: 10000 };
          default:
            return { low: 0, high: 0 };
        }
      case "ecommerce":
        const commerceRequired = Array.isArray(value) ? value[0] : value;
        switch (commerceRequired) {
          case "None":
            return { low: 0, high: 0 };
          case "Basic":
            return { low: 2000, high: 4000 };
          case "Advanced":
            return { low: 4000, high: 10000 };
          default:
            return { low: 0, high: 0 };
        }
      case "cms":
        const cmsRequired = Array.isArray(value) ? value[0] : value;
        switch (cmsRequired) {
          case "None":
            return { low: 0, high: 0 };
          case "Basic":
            return { low: 2000, high: 4000 };
          case "Advanced":
            return { low: 4000, high: 10000 };
          default:
            return { low: 0, high: 0 };
        }
      default:
        return { low: 0, high: 0 };
    }
  };

  const totalCost = Object.keys(pricingData).reduce(
    (acc, key) => {
      const cost = calculateCost(
        key,
        pricingData[key],
        parseInt(pricingData.pages)
      );
      return {
        low: acc.low + cost.low,
        high: acc.high + cost.high,
      };
    },
    { low: 0, high: 0 }
  );

  return (
    <section className="overflow-hidden relative flex flex-col justify-center md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
      <div className="container mx-auto">
        <h2 className="uppercase text-2xl text-center font-bold mb-4">
          Your Project Estimated Cost
        </h2>
        <div className="flex flex-col items-center">
          {loading ? (
            <div className="w-full bg-white dark:bg-slate-900 rounded-md shadow p-6">
              <Loader />
            </div>
          ) : (
            <table className="table-auto w-full bg-white dark:bg-slate-900 rounded-md shadow">
              <thead>
                <tr>
                  <th className="px-4 py-2"></th>
                  <th className="px-4 py-2"></th>
                  <th className="px-4 py-2">Low Cost</th>
                  <th className="px-4 py-2">High Cost</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr className="text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">Number of Pages</td>
                  <td className="border px-4 py-6">{pricingData.pages}</td>
                  <td className="border px-4 py-6">
                    ${calculateCost("pages", pricingData.pages).low}
                  </td>
                  <td className="border px-4 py-6">
                    ${calculateCost("pages", pricingData.pages).high}
                  </td>
                </tr>
                <tr className="text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">Style of Design</td>
                  <td className="border px-4 py-6">
                    {pricingData.style.join(", ")}
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "style",
                        pricingData.style,
                        pricingData.pages
                      ).low
                    }
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "style",
                        pricingData.style,
                        pricingData.pages
                      ).high
                    }
                  </td>
                </tr>
                <tr className="text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">Copywriting # of Pages</td>
                  <td className="border px-4 py-6">
                    {pricingData.copywriting}
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "copywriting",
                        pricingData.copywriting,
                        pricingData.pages
                      ).low
                    }
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "copywriting",
                        pricingData.copywriting,
                        pricingData.pages
                      ).high
                    }
                  </td>
                </tr>
                <tr className=" text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">SEO Placement</td>
                  <td className="border px-4 py-6">{pricingData.seo} Words</td>
                  <td className="border px-4 py-6">
                    ${calculateCost("seo", pricingData.seo).low}
                  </td>
                  <td className="border px-4 py-6">
                    ${calculateCost("seo", pricingData.seo).high}
                  </td>
                </tr>
                <tr className="text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">Responsive Design</td>
                  <td className="border px-4 py-6">
                    {pricingData.responsive.join(", ")}
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "responsive",
                        pricingData.responsive,
                        pricingData.pages
                      ).low
                    }
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "responsive",
                        pricingData.responsive,
                        pricingData.pages
                      ).high
                    }
                  </td>
                </tr>
                <tr className="text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">Database Integration</td>
                  <td className="border px-4 py-6">{pricingData.database}</td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "database",
                        pricingData.database,
                        pricingData.pages
                      ).low
                    }
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "database",
                        pricingData.database,
                        pricingData.pages
                      ).high
                    }
                  </td>
                </tr>
                <tr className="text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">e-Commerce Functionality</td>
                  <td className="border px-4 py-6">{pricingData.ecommerce}</td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "ecommerce",
                        pricingData.ecommerce,
                        pricingData.pages
                      ).low
                    }
                  </td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost(
                        "ecommerce",
                        pricingData.ecommerce,
                        pricingData.pages
                      ).high
                    }
                  </td>
                </tr>
                <tr className="text-slate-400 dark:text-slate-300">
                  <td className="border px-4 py-6">CMS</td>
                  <td className="border px-4 py-6">{pricingData.cms}</td>
                  <td className="border px-4 py-6">
                    $
                    {
                      calculateCost("cms", pricingData.cms, pricingData.pages)
                        .low
                    }
                  </td>
                  <td className="border px-4 py-2">
                    $
                    {
                      calculateCost("cms", pricingData.cms, pricingData.pages)
                        .high
                    }
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-6 font-bold">Total Cost</td>
                  <td className="border px-4 py-6"></td>
                  <td className="border px-4 py-6 font-bold">
                    ${totalCost.low}
                  </td>
                  <td className="border px-4 py-6 font-bold">
                    ${totalCost.high}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
}
