/** @format */

import React from "react";

import Logo from "../assets/images/logo-icon-64-01.png";

export default function Loader() {
  return (
    <>
      <div className="flex justify-center items-center h-full">
        <div className="text-center">
          <div className="logo">
            <img
              src={Logo}
              className="d-block mx-auto animate-bounce"
              alt="Loading"
            />
          </div>
        </div>
      </div>
    </>
  );
}
