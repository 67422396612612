import React,{ useState } from "react";
import axios from 'axios';
import Modal from "./Modal";


export default function Contact() {
    const [data, setData] = useState({
        name: '',
        email: '',
        subject: '',
        comments: ''
    })

    const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({ title: '', body: '' });

    const handleChange = (e) => {
        setData({
          ...data,
          [e.target.name]: e.target.value
        });
      };

    

      const handleSubmit = (e) => {
        e.preventDefault();
    
        axios.post("https://hwd-c96795c1f454.herokuapp.com/send", data)
          .then((response) => {
            setModalMessage({
                title: 'Message Sent',
                body: 'Thank you for your message, we will get back to you shortly.'
              });
           setModalOpen(true);
          })
          .catch((error) => {
            setModalMessage({
                title: 'Error',
                body: 'There was an error sending your message. Please try again later.'
              });
            setModalOpen(true);
          });
      };


    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Contact us</h6>
                        <h2 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Ready to start your project?</h2>

                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto"></p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                <form onSubmit={handleSubmit} netlify>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="name" id="name" type="text" className="form-input" placeholder="Name :" value={data.name} onChange={handleChange} />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="email" id="email" type="email" className="form-input" placeholder="Email :" value={data.email}
            onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input name="subject" id="subject" className="form-input" placeholder="Subject :" value={data.subject}
            onChange={handleChange}/>
                                        </div>

                                        <div className="mb-5">
                                            <textarea name="comments" id="comments" className="form-input textarea h-28" placeholder="Message :" value={data.comments}
            onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center">Send Message</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Phone</h5>
                                        <a href="tel:+44 78535 375" className="text-slate-400">+44 78535 3755</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Email</h5>
                                        <a href="mailto:hello@hackneywebdesigns.com" className="text-slate-400">hello@hackneywebdesigns.com</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Location</h5>
                                        <p className="text-slate-400 mb-2">230 Tiverton Road, Tottenham, London, N15 6RS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} message={modalMessage} />
        </>
    );

}
